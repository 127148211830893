
import http from '@/utils/http'
import { defineComponent, onMounted, ref } from 'vue'
import { format_date } from '../../../utils/index'
import { message } from 'ant-design-vue'

const columns = [
  {
    title: 'Key',
    dataIndex: 'key',
    key: 'key',
    align: 'center'
  },
  {
    title: 'Value',
    dataIndex: 'val',
    key: 'val',
    align: 'center'
  },
  {
    title: '编辑',
    dataIndex: '',
    key: '',
    align: 'center',
    slots: { customRender: 'edit' }
  }
]

export default defineComponent({
  name: 'TaichiConfig',
  setup () {
    const total = ref(0)
    const currentPage = ref(1)
    const dataSource = ref([])
    const iconLoading = ref(false)
    const visible = ref(false)
    const form = ref({ app_key: '' })
    const modalTitle = ref('')
    const modalData = ref({
      key: '',
      val: ''
    })

    const paginationChange = (page: any) => {
      currentPage.value = page
      getList()
    }

    const getList = () => {
      http(`/tokenapi/taichi/conf?page=${currentPage.value}`, {
        method: 'get',
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          dataSource.value = res.data
          total.value = dataSource.value.length
        }
      })
    }

    const setting = (data: any) => {
      visible.value = true
      modalTitle.value = '编辑'
      modalData.value = {
        key: data.key,
        val: data.val
      }
      
    }

    const handleOk = () => {
      console.log('modalData=>>>', modalData.value)
      http(`/tokenapi/taichi/conf`, {
        method: 'post',
        body: JSON.stringify({
          ...modalData.value,
        }),
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          visible.value = false
          getList()
        }
        message.info(res.msg)
      })
    }

    const add = () => {
      visible.value = true
      modalTitle.value = '新增'
      modalData.value = {
        key: '',
        val: ''
      }
    }

    const del = (data: any) => {
      http(`/tokenapi/taichi/conf`, {
        method: 'delete',
        body: JSON.stringify({
          key: data.key,
          val: data.val
        }),
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          getList()
        }
        message.info(res.msg)
      })
    }

    onMounted(() => {
      getList()
    })

    return {
      columns,
      total,
      currentPage,
      paginationChange,
      dataSource,
      iconLoading,
      form,
      format_date,
      visible,
      setting,
      modalData,
      handleOk,
      add,
      modalTitle,
      del
    }
  }
})
